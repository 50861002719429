export const infoPlugin = {
  beforeRender({ header, params }) {
    header.info = {
      type: '@sitebase/info',
      data: {
        id: 'hrs-info',
        text: params.text,
        buttons: params.buttons,
      },
    };
  },
};
